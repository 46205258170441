<template>
  <div>
    <div class="appeal_box">
      <div class="top_title">{{ $t("main.text3") }}</div>
      <div class="radin_box">
        <el-radio-group v-model="radio" @change="radio_change">
          <div v-if="type == 1">
            <div class="radio_item">
              <el-radio :label="1">{{ $t("main.text4") }}</el-radio>
            </div>
            <div class="radio_item">
              <el-radio :label="2">{{ $t("main.text5") }}</el-radio>
            </div>
          </div>

          <div v-if="type == 2">
            <div class="radio_item">
              <el-radio :label="3">{{ $t("main.text6") }}</el-radio>
            </div>
            <div class="radio_item">
              <el-radio :label="4">{{ $t("main.text7") }}</el-radio>
            </div>
          </div>

          <div class="radio_item">
            <el-radio :label="5">{{ $t("main.text8") }}</el-radio>
          </div>
        </el-radio-group>
      </div>

      <el-input
        class="textarea_box"
        :disabled="radio != 5"
        v-if="radio == 5"
        type="textarea"
        v-model="textarea"
      ></el-input>
      <div class="btm_btn" @click="appeal()">{{ $t("main.text9") }}</div>
    </div>
  </div>
</template>

<script>
import { appeal } from "../../../api/api";
export default {
  computed: {
    order_id() {
      return this.$route.query.order_id;
    },
    type() {
      return this.$route.query.type;
    },
  },
  data() {
    return {
      radio: 1,
      textarea: "",
    };
  },
  beforeMount() {
    sessionStorage.setItem("hichat_title", "申诉");
  },
  mounted() {
    
  },
  methods: {
    appeal() {
      if (this.textarea == "" && this.radio == 5) return false;
      appeal({
        order_id: this.order_id,
        appeal_type: this.radio,
        appeal_reason: this.textarea,
      }).then((res) => {
        if (res.data.code == 200) {
          this.msg("success", this.$t("main.text10"));
          this.$router.go(-1);
        } else {
          this.msg("error", res.data.msg);
        }
      });
    },
    radio_change() {
      this.textarea = "";
    },
  },
};
</script>

<style scoped>
.appeal_box {
  background: #fff;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 1.75rem 1.25rem;
}
.top_title {
  color: #353535;
  font-size: 1rem;
  font-weight: 600;
}
.radin_box {
  margin-top: 0.5rem;
}
.radio_item {
  padding: 0.5rem 0;
}
.radio_item >>> .el-radio {
  font-size: 0.875rem;
}
.textarea_box >>> .el-textarea__inner {
  border-radius: 0.375rem;
  background: #f5f6f7;
  height: 7.5rem;
}
.btm_btn {
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  border-radius: 0.25rem;
  background: #2982d8;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 1.25rem;
}
</style>
